import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
  static readonly USER_ROLE = {id: 4, name: 'User'};

  constructor (private httpClient: HttpClient) {}

  getRoles() {
    return this.httpClient.get('/api/roles');
  }

  createRole(role) {
    return this.httpClient.post('/api/roles', role ).pipe(map((data: any) => data.subscribe((response: any) => response)));
  }

  updateRole(id, role) {
    return this.httpClient.put('/api/roles/' + id, role).pipe(catchError(this.handleError));
  }

  async deleteRole(id) {
    return this.httpClient.delete('/api/roles/' + id, { responseType: 'text' }).toPromise();
  }

  private handleError(error: any) {
    return throwError(error);
  }

}
