import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService{

  private url = '/api/projects/';

  constructor(private httpClient: HttpClient) { }
  
  getEmpty() {
    const result = {
      id: -1,
      coordinator: {id: undefined, lastname: ''},
      organisation: {id: undefined, organisationName: 'Het Nieuwe Wonen'},
      description: '',
      startDate: '',
      endDate: '',
      status: {id: undefined},
      comment: ''
    };
    return result;
  }

  getAll() {
    return this.httpClient.get(this.url);
  }

  create(project: any ) {
    return this.httpClient.post(this.url, project ).pipe(catchError(this.handleError));
  }

  update(id, project: any) {
    return this.httpClient.put(this.url + id, project).pipe(catchError(this.handleError));
  }

  async delete(id) {
    return this.httpClient.delete(this.url + id, { responseType: 'text' }).toPromise();
  }

  private handleError(error: any) {
    return throwError(error);
  }
}