import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private url = '/api/organisations/';

  constructor(private httpClient: HttpClient) { }
  
  getEmpty() {
    const result = {
      id: undefined,
      organisationName: '',
      address: '',
      zipcode: '',
      city: '',
      phone: '',
      email: '',
      organisationType : { id: undefined, description: 'Instantie'}
    };
    return result;
  }

  getAll() {
    return this.httpClient.get(this.url);
  }

  create(organisation: any ) {
    return this.httpClient.post(this.url, organisation ).pipe(catchError(this.handleError));
  }

  update(id, organisation: any) {
    return this.httpClient.put(this.url + id, organisation).pipe(catchError(this.handleError));
  }

  async delete(id) {
    return this.httpClient.delete(this.url + id, { responseType: 'text' }).toPromise();
  }

  private handleError(error: any) {
    return throwError(error);
  }
}

