import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RoleService } from './role.service';

@Injectable({
    providedIn: 'root'
})

export class UserService {

  constructor (private httpClient: HttpClient) {}

  getEmptyUser() {
      const result = {
        username: '',
        lastname: '',
        firstname: '',
        company: '',
        role: RoleService.USER_ROLE,
      };

      return result;
    }

  getUsers() {
    return this.httpClient.get('/api/users');
  }

  createUser(user) {
    return this.httpClient.post('/api/users/', user, { responseType: 'json'} );
  }

  updateUser(id, user) {
    if (user.newPassword === null || user.newPassword === undefined || user.newPassword === '' ) {
      delete user.newPassword;
    }
    return this.httpClient.put('/api/users/' + id, user).pipe(catchError(this.handleError));
  }

  async deleteUser(id) {
    return this.httpClient.delete('/api/users/' + id, { responseType: 'text' }).toPromise();
  }

  logLogin(user) {
    this.httpClient.post('/api/users/successfulLogin/', user.id).subscribe(() => {});
  }

  getAllRelatedToSiteId(siteId: number) {
    return this.httpClient.get('/api/users/building/' + siteId);
  }

  private handleError(error: any) {
    return throwError(error);
  }
}
