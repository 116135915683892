import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private url = '/api/questions/';

  constructor(private httpClient: HttpClient) { }
  
  getEmpty() {
    const result = {
      id: -1,
      };
    return result;
  }

  getAll() {
    return this.httpClient.get(this.url);
  }

  create(project: any ) {
    return this.httpClient.post(this.url, project ).pipe(catchError(this.handleError));
  }

  update(id, project: any) {
    return this.httpClient.put(this.url + id, project).pipe(catchError(this.handleError));
  }

  async delete(id) {
    return this.httpClient.delete(this.url + id, { responseType: 'text' }).toPromise();
  }


  setAnswer(answer: any ) {
    console.log(answer);
    return this.httpClient.post(this.url+"answer/", answer ).pipe(catchError(this.handleError));
  }


  private handleError(error: any) {
    return throwError(error);
  }
}
