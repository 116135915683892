import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  private url = '/api/appointments/';

  constructor(private httpClient: HttpClient) { }
  
  getEmpty() {
    const result = {
      id: undefined,
      dateCreate: formatDate(new Date(), 'YYYY-MM-dd', 'en-US'),
      status: {id: undefined, description: ''},
      firstname: '',
      initials: '',
      infix: '',
      lastname: '',
      street: '',
      streetnumber: '',
      zipcode: '',
      city: '',
      phone: '',
      email: '',
      comment: '',
      dateAppointment: '',
      coach1: null,
      coach2: null,
      houseType: {id:undefined, description: ''},
      houseCorp: null,
      project: {id:undefined, description: ''},
      answers:null
    };
    return result;
  }

  addAnswer(arr:any, answer:any) :any {
    if (arr === undefined || arr === null) {
      arr = [];
    }
    const found = arr.some(item => item.questionId === answer.questionId);
    if (!found) arr.push({ questionId: answer.questionId, answer:answer.answer });
    if (found) arr.map(item => { (item.questionId === answer.questionId) ? item.answer = answer.answer : '';}) 
    return arr;
  } 

  getAnswer(arr:any, questionId: number):any {
    if (arr === undefined || arr === null) {
      arr = [];
    }
    return arr.find(q => q.questionId === questionId);
  }

  convertAnswersToObjects(string :string) : any {
    if (string === null || string === undefined) 
      return null;
    string = string.replace('"[', '[');
    string = string.replace(']"', ']');
    //string = string.replace(/\\/g, '');
  
    return JSON.parse(string); 
   }


  getAll():any {
    return this.httpClient.get(this.url);
  }

  getAllFromCoach(id:number):any {
    return this.httpClient.get(this.url+"coach/"+id);
  }

  getApp(appointmentId: any) {
    return this.httpClient.get(this.url+appointmentId);
  }

  create(appointment: any ) {
    return this.httpClient.post(this.url, appointment ).pipe(catchError(this.handleError));
  }

  update(id, appointment: any) {
    return this.httpClient.put(this.url + id, appointment).pipe(catchError(this.handleError));
  }

  async delete(id) {
    return this.httpClient.delete(this.url + id, { responseType: 'text' }).toPromise();
  }

  private handleError(error: any) {
    return throwError(error);
  }
}